// External libraries
import { useMutation, UseMutationResult } from '@tanstack/react-query'

// Global utilities and constants
import { queryClient } from '@/App'
import { toast } from 'sonner'

// Services and API calls
import { processFiles } from '@/api'

export type DocumentInput = {
   courseName: string
   fileName: string
   createdBy: string
}

const useProcessFiles = (): UseMutationResult<{ message: string }, Error, DocumentInput[], unknown> => {
   return useMutation({
      mutationFn: async (documents: DocumentInput[]) => {
         const documentsPaths = documents.map((document) => `${document.courseName}/${document.fileName}`)
         return await processFiles(documentsPaths)
      },
      onSuccess: (data, variables) => {
         toast.success('Batch Processing Initiated', {
            description: 'Files are being processed, status will be updated shortly',
         })

         queryClient.invalidateQueries({
            queryKey: ['documents'],
         })
      },
      onError: (error) => {
         toast.error("Couldn't process files", {
            description: error instanceof Error ? error.message : 'Please try again',
         })
      },
   })
}

export default useProcessFiles
