import { toast } from 'sonner'
import { useMemo } from 'react'
import { useConversationsSearchParams } from '@/hooks/monitor/useConversationsSearchParams'
import useConversationsList from '@/hooks/monitor/useConversationsList'
import { default as ConversationsDataTable } from '@/components/Table/data-table'
import { CoursesTableSkeleton } from '@/components/Skeletons/CoursesTableSkeleton'
import { default as conversationsColumns } from '@/components/Conversations/table/columns'
import { DataTableToolbar as ConversationsDataTableToolbar } from '@/components/Conversations/table/data-table-toolbar'
import ConversationSearchForm from '@/components/Conversations/ConversationsSearchForm'

export default function Conversations() {
   const { courseName: conversationCourseName, username: conversationUsername } = useConversationsSearchParams()

   const {
      data: conversationsList,
      isLoading: conversationsLoading,
      error: conversationsError,
   } = useConversationsList(conversationCourseName, conversationUsername)

   const content = useMemo(() => {
      if (conversationsError) {
         toast.error(conversationsError.message)
      } else if (conversationsList && conversationsList.length !== 0) {
         return (
            <ConversationsDataTable
               columns={conversationsColumns}
               data={conversationsList}
               DataTableToolbar={ConversationsDataTableToolbar}
            />
         )
      } else if (conversationsLoading) {
         return <CoursesTableSkeleton />
      }
      // Default or fallback content
      return <></>
   }, [conversationsError, conversationsList, conversationsLoading])

   return (
      <div className="h-full w-full justify-center items-center">
         <ConversationSearchForm />
         <div className="w-full pt-4">{content}</div>
      </div>
   )
}
