import { useQuery } from '@tanstack/react-query'
import { listConversationsUsersFromDB } from '@/api' // replace with actual import
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/store'

/**
 * Custom hook for fetching a list of users from conversations in the database.
 * Utilizes `@tanstack/react-query` for data fetching and caching.
 * Fetches users using the authenticated user's `userId` from Jotai state.
 *
 * @returns {object} An object containing the query result, including:
 * - `data`: The list of users involved in conversations.
 * - `isLoading`: A boolean indicating whether the query is loading.
 * - `error`: The error object if the query fails.
 * - Other methods provided by `useQuery` for query control.
 *
 * The `queryKey` is set to ['conversations-list-users', userId] to uniquely identify this query's cache entry.
 * The `queryFn` is responsible for executing the fetch operation, with the `signal` parameter used to optionally cancel the request.
 */
const useConversationsListUsers = () => {
   const { userId } = useAtomValue(userAtom)
   return useQuery({
      queryKey: ['conversations-list-users', userId],
      queryFn: ({ signal }) => listConversationsUsersFromDB(userId, signal),
   })
}

export default useConversationsListUsers
