'use client'

// External libraries
import { ColumnDef } from '@tanstack/react-table'
import { Link } from 'react-router-dom'
// Internal modules
import { Conversation } from '@/types'

// Components
import { DataTableColumnHeader } from '@/components/Table/data-table-column-header'
import { Filter } from '@/components/Table/filters'

import { MessageSquareText } from 'lucide-react'
import { buttonVariants } from '@/components/ui/button'

// Hooks

// This type is used to define the shape of our data.
const columns: ColumnDef<Readonly<Conversation>>[] = [
   {
      accessorKey: 'title',
      header: ({ column }) => {
         return <DataTableColumnHeader column={column} title="Title" />
      },
   },
   {
      accessorKey: 'time',
      header: ({ column }) => {
         return <DataTableColumnHeader column={column} title="Time" />
      },
      cell: ({ table, row }) => {
         const options = Filter({ column: table.getColumn('time') })
         const status = options.find((status) => status.value === row.getValue('time'))

         if (!status) {
            return null
         }

         return status.label
      },
      filterFn: (row, id, value) => {
         return value.includes(row.getValue(id))
      },
   },
   {
      accessorKey: 'date',
      header: ({ column }) => {
         return <DataTableColumnHeader column={column} title="Date" />
      },
      cell: ({ table, row }) => {
         const options = Filter({ column: table.getColumn('date') })
         const status = options.find((status) => status.value === row.getValue('date'))

         if (!status) {
            return null
         }

         return status.label
      },
      filterFn: (row, id, value) => {
         return value.includes(row.getValue(id))
      },
   },
   {
      header: ({ column }) => {
         return <DataTableColumnHeader disableSort column={column} title="View Chat" />
      },
      accessorKey: 'viewChat',
      cell: ({ row }) => {
         const ConversationData: Conversation = {
            courseName: row.original.courseName,
            conversationId: row.original.conversationId,
            title: row.getValue('title'),
            time: row.getValue('time'),
            date: row.getValue('date'),
         }

         return (
            <Link
               className={buttonVariants({ variant: 'ghost'})}
               to={`${encodeURIComponent(ConversationData.conversationId)}/${encodeURIComponent(ConversationData.title)}`}
            >
               <MessageSquareText className="w-5 h-5" />
            </Link>
         )
      },
   },
]

export default columns
