import { useQuery } from '@tanstack/react-query'
import { getCoursesWithLogs } from '@/api'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/store'

/**
 * Custom hook for fetching courses along with their associated logs from the database for any specified user.
 * Utilizes the `@tanstack/react-query` for data fetching, caching, and automatic updates.
 *
 * This hook is designed to fetch data not just for the current user but for any user by accepting a `userId` parameter.
 * It retrieves the current user's `idToken` from the global state (using Jotai's `useAtomValue` to access `userAtom`),
 * which is necessary for authentication and authorization when requesting the courses and logs data from the backend.
 *
 * In cases where the `idToken` or `userId` is missing, the hook will return an empty array to ensure the query function
 * never returns `undefined`, adhering to the requirements of `@tanstack/react-query`.
 *
 * @param {string | null} userId - The ID of the user for whom the courses and logs are being fetched. This allows
 *                                 fetching data for any specified user, not limited to the current user.
 * @returns An object containing:
 * - `data`: An array of course objects with their logs, fetched from the backend for the specified user. Returns an
 *           empty array if `idToken` or `userId` is missing.
 * - `error`: An error object if the fetch operation fails.
 * - `isLoading`: A boolean indicating if the fetch operation is in progress.
 * - `isError`: A boolean indicating if there was an error during the fetch operation.
 */
export const useCoursesWithLogs = (userId: string | null) => {
   const { idToken } = useAtomValue(userAtom)
   return useQuery({
      queryKey: ['courses-with-logs', userId],
      queryFn: async ({ signal }) => {
         if (!idToken || !userId) {
            // on page load this will be called with userId as null
            // console.log('idToken or userId is missing')
            return await Promise.resolve([])
         }
         return (await getCoursesWithLogs(idToken, userId, signal)) || []
      },
   })
}
