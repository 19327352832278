// External libraries
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

// Internal modules - Components
import { Button, buttonVariants } from '@/components/ui/button'
import {
   Command,
   CommandDialog,
   CommandEmpty,
   CommandGroup,
   CommandInput,
   CommandItem,
   CommandList,
   CommandSeparator,
   CommandShortcut,
} from '@/components/ui/command'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'

// Internal modules - Hooks and Jotai Atoms
import { useConversationsSearchParams } from '@/hooks/monitor/useConversationsSearchParams'
import useConversationsListUsers from '@/hooks/monitor/useConversationsListUsers'
import { useCoursesWithLogs } from '@/hooks/monitor/useCoursesWithLogs'

// Internal modules - Global utilities and constants
import { cn } from '@/lib/utils'

// assets
import { Search, PlusCircle, CheckIcon } from 'lucide-react'

export const formSchema = z.object({
   username: z.string().email(),
   courseName: z.string().min(3).max(9),
})

export default function ConversationSearchForm() {
   const { data: usersList, isLoading: usersListLoading } = useConversationsListUsers()
   const { username: usernameParam, setSearchParams } = useConversationsSearchParams()
   const {
      data: coursesList,
      error: coursesListError,
      isLoading: coursesListLoading,
   } = useCoursesWithLogs(usernameParam)

   const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),
      defaultValues: {
         username: usernameParam,
         courseName: '',
      },
   })

   const onSubmit = (values: z.infer<typeof formSchema>) => {
      setSearchParams({ username: values.username, courseName: values.courseName })
   }

   return (
      <Form {...form}>
         <form onSubmit={form.handleSubmit(onSubmit)} className="mt-8 flex">
            <div className="flex flex-col w-full sm:w-3/4 sm:flex-row justify-between mx-auto items-stretch gap-3 ">
               <FormField
                  control={form.control}
                  name="username"
                  render={({ field }) => (
                     <FormItem className="w-full sm:w-[45%] space-y-0 ">
                        <FormLabel htmlFor="username"></FormLabel>
                        <FormControl className="h-11">
                           <Popover>
                              <PopoverTrigger
                                 disabled={coursesListLoading || usersListLoading}
                                 className={cn(buttonVariants({ variant: 'outline', size: 'lg', className: 'w-full' }))}
                              >
                                 {!field.value && <PlusCircle className="size-5 text-primary pr-1" />}
                                 {field.value ? <span className="truncate w-full">{field.value}</span> : 'Select User'}
                              </PopoverTrigger>
                              <PopoverContent align="start">
                                 <Command>
                                    <CommandInput placeholder="Select User" />
                                    <CommandList>
                                       <CommandEmpty>No results found.</CommandEmpty>
                                       <CommandGroup heading="Users">
                                          {usersList?.map((username) => (
                                             <CommandItem
                                                onSelect={() => {
                                                   setSearchParams({
                                                      username,
                                                   })
                                                   form.setValue('courseName', '')
                                                   field.onChange({ target: { value: username } })
                                                }}
                                                key={username}
                                                value={username}
                                             >
                                                <div
                                                   className={cn(
                                                      'mr-2 flex size-4 items-center justify-center rounded-sm border border-primary',
                                                      username === usernameParam
                                                         ? 'bg-primary text-primary-foreground'
                                                         : 'opacity-50 [&_svg]:invisible',
                                                   )}
                                                >
                                                   <CheckIcon className={cn('size-4')} />
                                                </div>
                                                {username}
                                             </CommandItem>
                                          ))}
                                       </CommandGroup>
                                       <CommandSeparator />
                                    </CommandList>
                                 </Command>
                              </PopoverContent>
                           </Popover>
                        </FormControl>
                        <FormMessage />
                     </FormItem>
                  )}
               />
               <FormField
                  control={form.control}
                  name="courseName"
                  render={({ field }) => (
                     <FormItem className="w-full sm:w-[45%]  space-y-0 ">
                        <FormLabel htmlFor="courseName"></FormLabel>
                        <FormControl className="h-11">
                           <Popover>
                              <PopoverTrigger
                                 disabled={coursesListLoading || usersListLoading}
                                 className={cn(buttonVariants({ variant: 'outline', size: 'lg', className: 'w-full' }))}
                              >
                                 {!field.value && <PlusCircle className="pr-1 size-5 text-primary" />}
                                 {field.value ? field.value : 'Select Course'}
                              </PopoverTrigger>
                              <PopoverContent align="start">
                                 <Command>
                                    <CommandInput placeholder="Select Course" />
                                    <CommandList>
                                       <CommandEmpty>No results found.</CommandEmpty>
                                       <CommandGroup heading="Courses">
                                          {coursesList?.map((course) => (
                                             <CommandItem
                                                onSelect={() => {
                                                   field.onChange({ target: { value: course } })
                                                }}
                                                key={course}
                                                value={course}
                                             >
                                                <div
                                                   className={cn(
                                                      'mr-2 flex size-4 items-center justify-center rounded-sm border border-primary',
                                                      course === field.value
                                                         ? 'bg-primary text-primary-foreground'
                                                         : 'opacity-50 [&_svg]:invisible',
                                                   )}
                                                >
                                                   <CheckIcon className={cn('size-4')} />
                                                </div>
                                                {course}
                                             </CommandItem>
                                          ))}
                                       </CommandGroup>
                                       <CommandSeparator />
                                    </CommandList>
                                 </Command>
                              </PopoverContent>
                           </Popover>
                        </FormControl>
                        <FormMessage />
                     </FormItem>
                  )}
               />
               <div className="h-11">
                  <Button type="submit" size="icon" className={cn('h-full w-full flex-grow flex')}>
                     <Search className="size-5 sm:w-20" />
                  </Button>
               </div>
            </div>
         </form>
      </Form>
   )
}
