// External libraries
import { useState } from 'react'
// Components
import {
   Dialog,
   DialogContent,
   DialogDescription,
   DialogHeader,
   DialogTitle,
   DialogFooter,
   DialogTrigger,
} from '@/components/ui/dialog'
import { Button, buttonVariants } from '@/components/ui/button'
import { PlusCircleIcon, UserRoundCog } from 'lucide-react'

// import { PlusCircledIcon } from '@radix-ui/react-icons'
import UserForm from '@/components/ManageUsers/UserForm'

export default function UserDialog({
   editUser,
   currentRole,
   userEmail,
}: Readonly<{
   editUser?: boolean
   currentRole?: string
   userEmail?: string
}>) {
   const [isOpen, setIsOpen] = useState(false)
   return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
         {!editUser ? (
            <DialogTrigger className={`h-8 lg:flex ${buttonVariants({ size: 'sm' })}`}>
               <PlusCircleIcon className="mr-2 h-4 w-4" />
               Add User
            </DialogTrigger>
         ) : (
            <DialogTrigger asChild>
               <UserRoundCog className="w-5 h-5" />
            </DialogTrigger>
         )}

         <DialogContent>
            <DialogHeader>
               <DialogTitle className="pb-4">User Details</DialogTitle>
               {/* do not remove DialogDescription is it is required for html semantics */}
               <DialogDescription></DialogDescription>
            </DialogHeader>
            <section>
               {!editUser ? (
                  <UserForm closeDialog={() => setIsOpen(false)} />
               ) : (
                  <UserForm currentRole={currentRole} userEmail={userEmail} closeDialog={() => setIsOpen(false)} />
               )}
            </section>
         </DialogContent>
      </Dialog>
   )
}
