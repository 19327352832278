// External libraries
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { format } from 'date-fns'

// Internal modules - Components
import { Button, buttonVariants } from '@/components/ui/button'
import {
   Command,
   CommandDialog,
   CommandEmpty,
   CommandGroup,
   CommandInput,
   CommandItem,
   CommandList,
   CommandSeparator,
   CommandShortcut,
} from '@/components/ui/command'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
// import { Input } from '@/components/ui/input'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Calendar } from '@/components/ui/calendar'

// Internal modules - Hooks and Jotai Atoms
import { useLogsSearchParams } from '@/hooks/monitor/useLogsSearchParams'
import useCourses from '@/hooks/courses/useCourses'

// Internal modules - Global utilities and constants
import { cn } from '@/lib/utils'

// assets
import { Search, PlusCircle, CheckIcon, CalendarIcon } from 'lucide-react'
import { toast } from 'sonner'

const formatDate = (date: Date) => {
   const year = date.getFullYear()
   const month = String(date.getMonth() + 1).padStart(2, '0')
   const day = String(date.getDate()).padStart(2, '0')

   return `${month}-${day}-${year}`
}

const fallbackDate = formatDate(new Date())

export const formSchema = z.object({
   courseName: z.string().min(9, 'Course name is required'),
   date: z.coerce.date({ message: 'Date is required' }),
})

export default function LogsSearchForm() {
   const {
      data: coursesList,
      isLoading: isCoursesLoading,
      isPending: isCoursesPending,
      error: courseListError,
   } = useCourses()
   const { courseName, date, setSearchParams } = useLogsSearchParams()

   if (courseListError) {
      toast.error(courseListError.message)
   }

   const formattedDate = formatDate(new Date(date))

   const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),
      defaultValues: {
         courseName: courseName ?? '',
         date: date ? new Date(formattedDate) : new Date(fallbackDate),
      },
   })

   const onSubmit = (values: z.infer<typeof formSchema>) => {
      console.log('values', values)
      const formattedDate = formatDate(values.date)
      setSearchParams({ courseName: values.courseName, date: formattedDate })

      // form.reset()
   }

   return (
         <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="mt-8">
               <div className="flex flex-col w-full sm:w-3/4 sm:flex-row justify-between mx-auto items-stretch gap-3">
                  <FormField
                     control={form.control}
                     name="courseName"
                     render={({ field }) => (
                        <FormItem className="w-full sm:w-[45%] space-y-0">
                           <FormLabel htmlFor="course-name"></FormLabel>
                           <FormControl className="h-11">
                              <Popover>
                                 <PopoverTrigger
                                    className={`${buttonVariants({ variant: 'outline', size: 'lg', className: 'w-full' })}`}
                                 >
                                    {!field.value && <PlusCircle className="size-5 text-primary pr-1" />}
                                    {field.value ? (
                                       <span className="truncate w-full">{field.value}</span>
                                    ) : (
                                       'Select course'
                                    )}
                                 </PopoverTrigger>
                                 <PopoverContent align="start">
                                    <Command>
                                       <CommandInput placeholder="Enter course name" />
                                       <CommandList>
                                          <CommandEmpty>No results found.</CommandEmpty>
                                          <CommandGroup heading="Courses">
                                             {coursesList?.map((course) => (
                                                <CommandItem
                                                   disabled={isCoursesLoading || isCoursesPending}
                                                   onSelect={() => {
                                                      setSearchParams({
                                                         courseName: course.courseName,
                                                      })
                                                      field.onChange({ target: { value: course.courseName } })
                                                   }}
                                                   key={course.courseName}
                                                   value={course.courseName}
                                                >
                                                   <div
                                                      className={cn(
                                                         'mr-2 flex size-4 items-center justify-center rounded-sm border border-primary',
                                                         course.courseName === courseName
                                                            ? 'bg-primary text-primary-foreground'
                                                            : 'opacity-50 [&_svg]:invisible',
                                                      )}
                                                   >
                                                      <CheckIcon className={cn('size-4')} />
                                                   </div>
                                                   {course.courseName}
                                                </CommandItem>
                                             ))}
                                          </CommandGroup>
                                          <CommandSeparator />
                                       </CommandList>
                                    </Command>
                                 </PopoverContent>
                              </Popover>
                           </FormControl>
                           {/* <FormDescription>Course name must be 3 letters followed by 3 digits.</FormDescription> */}
                           <FormMessage />
                        </FormItem>
                     )}
                  />
                  <FormField
                     control={form.control}
                     name="date"
                     render={({ field }) => (
                        <FormItem className="w-full sm:w-[45%] space-y-0">
                           <FormLabel htmlFor="date"></FormLabel>
                           <FormControl className="h-11">
                              {/* <Input
                                 className={`${buttonVariants({ variant: 'outline', size: 'lg', className: 'w-full' })}`}
                                 disabled={isCoursesLoading || isCoursesPending}
                                 {...field}
                                 id="date"
                                 type="date"
                              /> */}
                              <Popover>
                                 <PopoverTrigger asChild>
                                    <Button
                                       size="lg"
                                       variant={'outline'}
                                       disabled={isCoursesLoading || isCoursesPending}
                                       className={cn(
                                          'w-full justify-start text-left font-normal',
                                          !field.value && 'text-muted-foreground',
                                       )}
                                    >
                                       <CalendarIcon className="mr-2 h-4 w-4 text-primary" />
                                       {field.value ? format(field.value, 'PPP') : <span>Pick a date</span>}
                                    </Button>
                                 </PopoverTrigger>
                                 <PopoverContent className="w-auto p-0" align="start">
                                    <Calendar
                                       mode="single"
                                       selected={field.value}
                                       onSelect={(date) => {
                                          field.onChange({ target: { value: date } })
                                       }}
                                       autoFocus
                                       startMonth={new Date(2019, 11)}
                                       endMonth={new Date()}
                                    />
                                 </PopoverContent>
                              </Popover>
                           </FormControl>
                           {/* <FormDescription>Select the date for the course.</FormDescription> */}
                           <FormMessage />
                        </FormItem>
                     )}
                  />
                  <div className="h-11">
                     <Button
                        type="submit"
                        size="icon"
                        className={cn('h-full w-full flex-grow flex')}
                        disabled={isCoursesLoading || isCoursesPending}
                     >
                        <Search className="size-5 sm:w-20" />
                     </Button>
                  </div>
               </div>
            </form>
         </Form>
   )
}
