// External libraries

// Components
import { buttonVariants } from '@/components/ui/button'
import {
   Dialog,
   DialogContent,
   DialogDescription,
   DialogHeader,
   DialogTitle,
   DialogFooter,
   DialogTrigger,
} from '@/components/ui/dialog'
import { NewCourseForm } from '@/components/ManageCourses/NewCourseForm'

// Assets
import { PlusCircleIcon } from 'lucide-react'

export default function NewCourseDialog() {

   return (
      <Dialog
      >
         <DialogTrigger className={`${buttonVariants({ size: 'sm' })}  ml-auto h-8 lg:flex`}>
            <PlusCircleIcon className="mr-2 h-4 w-4" />
            Add Course
         </DialogTrigger>
         <DialogContent>
            <DialogHeader>
               <DialogTitle className="pb-4">Add a New Course</DialogTitle>
               {/* <DialogDescription></DialogDescription> */}
            </DialogHeader>
            
            <section className="py-4">
               <div>
                  <NewCourseForm />
               </div>
            </section>
            {/* do not remove DialogDescription as it is required for html semantics */}
            <DialogDescription></DialogDescription>
         </DialogContent>
      </Dialog>
   )
}
