import { useSearchParams } from 'react-router-dom'

/**
 * Custom hook for managing and accessing search parameters related to conversations in the URL.
 * Utilizes the `useSearchParams` hook from `react-router-dom` for reading and updating the URL's search parameters.
 *
 * Manages the following search parameters:
 * - `courseName`: Filters conversations by the course name. Defaults to an empty string if not specified.
 * - `username`: Filters conversations by username. Incorrectly documented previously as filtering logs by date. Defaults to an empty string if not specified.
 * - `pageIndex`: Specifies the current page index for pagination. Defaults to 0 if not specified.
 * - `pageSize`: Specifies the number of conversation entries per page. Defaults to 10 if not specified.
 *
 * @returns An object containing:
 *   - The current values of the search parameters (`courseName`, `username`, `pageIndex`, `pageSize`).
 *   - A `setSearchParams` function to programmatically update the search parameters in the URL.
 */
export function useConversationsSearchParams() {
   const [searchParams, setSearchParams] = useSearchParams()
   const courseName = searchParams.get('courseName') ?? ''
   const username = searchParams.get('username') ?? ''
   const pageIndex = searchParams.get('pageIndex') ?? 0
   const pageSize = searchParams.get('pageSize') ?? 10

   return { courseName, username, pageIndex, pageSize, setSearchParams }
}
