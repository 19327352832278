import { ChatAPILog, MonitorAPIChatLogsColumns, Conversation, ConversationContent } from '@/types'
import { refreshAuthTokenAndUpdateUser } from '@/auth/utils'

const API_URL = import.meta.env.VITE_API_URL

type listChatLogsFromDBProps = {
   course_name: string
   date: string
   time?: string
   fileName?: string
   pageIndex: number
   pageSize: number
   signal: AbortSignal
}

/**
 * Fetches a paginated list of chat logs from the database for a specific course on a given date.
 *
 * This function constructs a query string with parameters for course name, date, page index, and page size
 * to fetch a paginated list of chat logs from the database. It makes a GET request to the server endpoint
 * specified by concatenating the API_URL with the endpoint path and the constructed query string. The request
 * includes an Authorization header with the provided ID token and supports cancellation through an AbortSignal.
 *
 * @param {listChatLogsFromDBProps} params - An object containing the parameters for the request:
 *                                           course_name (string) - The name of the course.
 *                                           date (string) - The date for which to fetch chat logs.
 *                                           pageIndex (number) - The index of the current page (for pagination).
 *                                           pageSize (number) - The number of items per page (for pagination).
 *                                           signal (AbortSignal) - An AbortSignal to cancel the request if needed.
 * @returns {Promise<{ rows: MonitorAPIChatLogsColumns[]; pageCount: number; rowCount: number }>} A promise that resolves to an object containing:
 *          rows (MonitorAPIChatLogsColumns[]) - An array of chat log entries.
 *          pageCount (number) - The total number of pages available.
 *          rowCount (number) - The total number of chat log entries available.
 * @throws {Error} Throws an error if the request fails for any reason, including network issues, server errors,
 *                 or if the request is aborted. The error message includes details about the failure.
 */
export const listChatLogsFromDB = async ({
   course_name,
   date,
   pageIndex,
   pageSize,
   signal,
}: listChatLogsFromDBProps): Promise<
   { rows: MonitorAPIChatLogsColumns[]; pageCount: number; rowCount: number } | undefined
> => {
   const query = `course_name=${encodeURIComponent(course_name)}&date=${encodeURIComponent(date)}&page_index=${encodeURIComponent(pageIndex)}&page_size=${encodeURIComponent(pageSize)}`
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/monitor/logs/list?${query}`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
         signal,
      })

      if (response.status === 204) {
         throw new Error('No chat logs found for the specified course and date')
      }

      const body = await response.json()
      if (!response.ok) {
         throw new Error(body.message)
      }

      return body
   } catch (error) {
      if (signal.aborted) {
         return
      }
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred while fetching chat logs from the database.')
   }
}

/**
 * Retrieves detailed log information for a specific course from the database.
 *
 * This function makes a GET request to the server, fetching detailed log information
 * such as thoughts for a specific course based on the provided parameters. It constructs
 * a query string with the course name, date, time, and file name to uniquely identify the log.
 * The request requires an ID token for authorization and supports cancellation through an AbortSignal.
 *
 * @param {string} courseName - The name of the course for which to retrieve log details.
 * @param {string} date - The date associated with the log.
 * @param {string} time - The time associated with the log.
 * @param {string} fileName - The name of the log file.
 * @param {AbortSignal} signal - An AbortSignal object that allows you to communicate with a fetch request
 *                               and abort it if necessary.
 * @returns {Promise<ChatAPILog>} A promise that resolves to a ChatAPILog object containing the detailed log information.
 * @throws {Error} Throws an error if the request fails, including when the request is aborted or if the
 *                 server responds with an error.
 */
export const getLogDetailsFromDB = async (
   courseName: string,
   date: string,
   time: string,
   fileName: string,
   signal: AbortSignal,
): Promise<ChatAPILog | undefined> => {
   const query = `?course_name=${encodeURIComponent(courseName)}&date=${encodeURIComponent(date)}&time=${encodeURIComponent(time)}&file_name=${encodeURIComponent(fileName)}`
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/monitor/logs/details${query}`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
         signal,
      })

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }

      const data = await response.json()
      const newChatAPILog: ChatAPILog = {
         thoughts: [data.thoughts[0], data.thoughts[1], data.thoughts[2], data.thoughts[3], data.thoughts[4]],
      }

      return newChatAPILog
   } catch (error) {
      if (signal.aborted) {
         return
      }
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred while fetching log details from the database.')
   }
}

/**
 * Fetches a list of courses with logs available.
 *
 * This function makes a GET request to a specified endpoint to retrieve a list of courses
 * that have logs available. It requires an ID token and a user ID for authorization in the request header,
 * and supports request cancellation through an AbortSignal.
 *
 * @param {string} userId - The user ID used for filtering the courses by the user.
 * @param {AbortSignal} signal - An AbortSignal object that allows you to communicate with a fetch request
 *                               and abort it if necessary.
 * @returns {Promise<string[]>} A promise that resolves to an array of course IDs.
 * @throws {Error} Throws an error if the request fails, including when the request is aborted or if the
 *                 server responds with an error.
 */
export const getCoursesWithLogs = async (
   idToken: string,
   userId: string,
   signal: AbortSignal,
): Promise<string[] | undefined> => {
   const query = `?user_id=${encodeURIComponent(userId)}`
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/monitor/logs/list-courses${query}`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
         signal,
      })

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }
      const data = await response.json()
      return data
   } catch (error) {
      if (signal.aborted) {
         return
      }
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred while fetching courses with logs from the database.')
   }
}

/**
 * Fetches a list of user IDs from the database that have participated in conversations.
 *
 * This function sends a GET request to a specified endpoint to retrieve a list of users involved in conversations.
 * It includes the authorization token in the request headers for authentication and supports request cancellation
 * through the AbortSignal. The function can filter conversations based on the provided `userId`. Admin or dev users
 * will have access to all conversations.
 *
 * @param {string} userId - The user ID to filter conversations. Admin/dev users can access all conversations.
 * @param {AbortSignal} signal - An AbortSignal object to allow for request cancellation.
 * @returns {Promise<string[]>} A promise that resolves to an array of user IDs.
 *
 * @throws {Error} Throws an error if the request fails for any reason, including network issues,
 *                 non-OK responses from the server, or if the request is aborted. The error message
 *                 provides details about the failure.
 */
export const listConversationsUsersFromDB = async (
   userId: string,
   signal: AbortSignal,
): Promise<string[] | undefined> => {
   const query = `?user_id=${encodeURIComponent(userId)}`
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/monitor/conversations/list-users${query}`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
         signal,
      })

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }
      const data = await response.json()
      return data
   } catch (error) {
      if (signal.aborted) {
         return
      }
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred while fetching users from the database.')
   }
}

/**
 * Fetches a list of conversations from the database for a specific user and course.
 *
 * This function sends a GET request to the server, including the user ID and course name as query parameters.
 * It handles both successful responses and various error scenarios, such as non-OK responses from the server,
 * no conversations found for the given parameters, and request abortion.
 *
 * @param {string} userId - The unique identifier of the user for whom conversations are being fetched.
 * @param {string} courseName - The name of the course for which conversations are being fetched.
 * @param {AbortSignal} signal - An AbortSignal object to allow for request cancellation.
 * @returns {Promise<Conversation[]>} A promise that resolves to an array of Conversation objects.
 *
 * @throws {Error} Throws an error if the request is not successful, including detailed error messages
 *                 for different failure scenarios.
 */
export const listConversationsFromDB = async (
   userId: string,
   courseName: string,
   signal: AbortSignal,
): Promise<Conversation[] | undefined> => {
   const query = `?course_name=${encodeURIComponent(courseName)}&user_id=${encodeURIComponent(userId)}`
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/monitor/conversations/list${query}`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
         signal,
      })

      if (response.status === 204) {
         throw new Error(`No conversations found for user ${userId} in course ${courseName}`)
      }

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.message)
      }

      const data = await response.json()
      return data
   } catch (error) {
      if (signal.aborted) {
         return
      }
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred while fetching conversations from the database.')
   }
}

/**
 * Reads conversation content from the database using the provided conversation ID.
 *
 * @param {string} conversationId - The unique identifier of the conversation to be read.
 * @param {AbortSignal} signal - An AbortSignal object that allows you to communicate with a fetch request
 *                               (or other asynchronous task) and abort it if necessary.
 * @returns {Promise<ConversationContent[]>} A promise that resolves to an array of ConversationContent objects.
 *
 * @throws {Error} Throws an error if the request fails, including when the request is aborted or if
 *                 the server responds with an error message.
 */
export const readConversationFromDB = async (
   conversationId: string,
   signal: AbortSignal,
): Promise<ConversationContent[] | undefined> => {
   const query = `?conversation_id=${encodeURIComponent(conversationId)}`
   try {
      const idToken = await refreshAuthTokenAndUpdateUser()

      const response = await fetch(`${API_URL}/api/monitor/conversations/read${query}`, {
         method: 'GET',
         headers: {
            Authorization: `Bearer ${idToken}`,
         },
         signal,
      })

      if (!response.ok) {
         const body = await response.json()
         throw new Error(body.error)
      }
      const data = await response.json()
      return data
   } catch (error) {
      if (signal.aborted) {
         return
      }
      if (error instanceof Error) {
         throw error
      }
      throw new Error('An error occurred while reading conversation content from the database.')
   }
}
